body {
  font-family: 'Tinos', serif;
  background-color: #0f2523;
  color: #535353;
}

.App {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 70vh;
  align-items: center;
  background-color: #f3f2ed;

  /* height: 100%; */
}

.header {
  height: 7vh;
  background-color: #0f2523;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3vh
}

.logoText {
  margin: 0px;
  font-family: 'Lato', serif;
  color: #f3f2ed;
}

.footer {
  height: 3vh;
  background-color: #0f2523;
}

.textColumn {
  /* margin: 10% 0%; */
  margin: 7% 0%;
  width: 55%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageColumn {
  /* margin: 10% 0%; */
  width: 45%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  justify-content: center;
}

.inputContainer {
  width: 85%;
  display: flex;
  flex-direction: row;
  margin: 30px 0px;
}

input {
  display: flex;
  flex: 5;
  height: 1.5em;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid rgb(185, 185, 185);
  border-radius: 5px;
  font-size: 1em;
  font-family: 'Tinos', serif;
}

.submit {
  background-color: black;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  height: 1.5em;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.submit:hover {
  opacity: 0.5;
  cursor: pointer;
}

.titleText {
  color: black;
  width: 85%;
  margin: 10px;
  font-size: 2.78em;
  text-align: left;
  font-family: 'Lato';
  font-weight: 500;
}

.subTitleText {
  width: 85%;
  margin: 10px;
  font-size: 1.25em;
  text-align: left;
}

.socialContainer {
  width: 85%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logoShot {
  width: 0.8em;
  margin-right: 0.5em;
}

.socialShotFirst {
  width: 2em;
  height: 2em;
  border-radius: 100px;
  border: 0.25em solid #f3f2ed;
}

.socialShot {
  width: 2em;
  height: 2em;
  border-radius: 100px;
  margin-left: -0.6em;
  border: 0.25em solid #f3f2ed;
}

.socialText {
  width: 100%;
  margin: 10px;
  font-size: 1em;
  text-align: left;
}

.imageShot {
  width: 70%;
}

.successBox {
  border: 1px solid #10bf7a;
  background-color: #d3fbeb;
  width: 100%;
  padding-left: 10px;
  border-radius: 5px;
}

.successText {
  color: #0c905c
}


@media (max-width: 800px) {
  .App {
    flex-direction: column;
  }

  .textColumn,
  .imageColumn {
    flex: 1;
    width: 100%;
  }

  .imageColumn {
    margin-bottom: 10%;
  }

}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.iframeClass {
  border-radius: 10px;
  height: 20vh;
  overflow: hidden;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
}